function OptanonWrapper() {
	var cookieBannerContainer = document.getElementById('onetrust-consent-sdk');
	document.body.prepend(cookieBannerContainer);

	// Move Manage button into button group
	var bannerActionsContainer = document.querySelector('.banner-actions-container');
	var bannerBtnHandlerManage = document.getElementById('onetrust-pc-btn-handler');
	// if (bannerActionsContainer && bannerBtnHandlerManage) {
	// 	bannerActionsContainer.append(bannerBtnHandlerManage);
	// }

	// Wrap the Accept button and append the Reject button
	var preferenceBtnHandlerAccept = document.getElementById('accept-recommended-btn-handler');
	var preferenceBtnHandlerReject = document.querySelector('.ot-pc-refuse-all-handler');
	var preferenceBtnVirtualWrapper = createElementWithClass('div', 'virtual-ot-btn-wrapper');
	wrapElement(preferenceBtnHandlerAccept, preferenceBtnVirtualWrapper);
	if (preferenceBtnHandlerReject) {
		preferenceBtnVirtualWrapper.append(preferenceBtnHandlerReject);
	}

	if (!getCookie('OptanonAlertBoxClosed')) {
		// Expand accordion
		var accordionButtons = document.querySelectorAll('.ot-accordion-layout button');
		for (var i = 0; i < accordionButtons.length; i++) {
			accordionButtons[i].setAttribute('aria-expanded', 'true');
		}
	}

	// Handle inital load
	// var cookieBannerOverlay = document.getElementsByClassName('onetrust-pc-dark-filter');
	// var overlayControl = document.querySelector(".overlay-control");
	// if (!overlayControl) {
	// 	if (cookieBannerOverlay[0] && window.getComputedStyle(cookieBannerOverlay[0]).display === 'block')  {
	// 		document.body.classList.add('ot-overflow-hidden');
	// 	} else {
	// 		document.body.classList.remove('ot-overflow-hidden');
	// 	}
	// }


	// // Handle close/submit
	// document.querySelectorAll('#onetrust-accept-btn-handler,.onetrust-close-btn-handler,#accept-recommended-btn-handler,.save-preference-btn-handler, #onetrust-reject-all-handler, .ot-pc-refuse-all-handler').forEach(function(item) {
	// 	item.addEventListener('click', function() {
	// 		document.body.classList.remove('ot-overflow-hidden');
	// 	});
	// });


	function getCookie(name) {
		var value = '; ' + document.cookie;
		var parts = value.split('; ' + name + '=');
		if (parts.length == 2) {
			return parts
				.pop()
				.split(';')
				.shift();
		}
	}

	if (getCookie("OTVariant")) {
		var cookieVariantValue = getCookie("OTVariant");
		dataLayer.push({
			"testId3": cookieVariantValue
		})
	}

	function createElementWithClass(el, className) {
		element = document.createElement('div');
		element.className = className;
		return element;
	}

	function wrapElement(el, wrapper) {
		el.parentNode.insertBefore(wrapper, el);
		wrapper.appendChild(el);
	}


	const gpcEnabled = navigator.globalPrivacyControl;
	if (gpcEnabled) {
        var cookieBanner = document.getElementById("onetrust-consent-sdk");
        cookieBanner.classList.toggle("gpc-detected");
	}

	var gpcOnController = document.querySelector(".gpc-on"),
		gpcOKButton = document.getElementById("cookieGPCControlButton"),
		buttonContainer = document.querySelector(".banner-actions-container")

	if (gpcOnController && gpcOKButton) {
		buttonContainer.appendChild(gpcOKButton);

		if (gpcEnabled) {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.Close();
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
		else {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.AllowAll();
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
	}


	//–––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
	//
	//	GPC – Only for California
	//
	function oneTrustMiniBanner() {
		if(!gpcEnabled && !OneTrust.IsAlertBoxClosed() && document.getElementById('otCaliforniaTemplate__text')) {
			console.log('OneTrustMini Banner - v0.1.7'); // Please keep the version number so we can track what version is in production
	
			var otMini;	// HTML template for the Cookie Consent Banner will be stored here
			const expandedBannerCssClass = 'js-expanded';
			const hiddenBannerCssClass = 'js-hidden';
	
			// Check if the page is an Academy page (= "Signal" page - AVG uses different naming)
			const isAcademyPage = /avg\.com\/[^\/]+\/signal(\/.*)?(\?.*)?(#.*)?$/.test(window.location.href);



			//
			//	1. Get data from OneTrust Cookie consent banner (<div id="onetrust-banner-sdk">)
			//
			const otData = {
				text: document.getElementById('otCaliforniaTemplate__text').textContent, // get text "This site uses"
				cookiePolicyLink: {
					text: document.querySelector(".ot-cookie-policy-link").textContent, // text for the link – "Cookies"
					href: document.querySelector(".ot-cookie-policy-link").getAttribute("href"), // link href attribute value
					ariaLabel: document.querySelector(".ot-cookie-policy-link").getAttribute("aria-label") // link aria-label attribute value
				},
				buttons: {
					accept: OneTrust.GetDomainData().AlertAllowCookiesText, // text for button "Accept"
					reject: OneTrust.GetDomainData().BannerRejectAllButtonText // text for button "Reject"
				}
			};


			//
			// 2. Create OneTrust Mini Banner
			//
			function createOneTrustMiniBanner() {
				//
				// 2.1 Create main container
				//
				otMini = document.createElement('div');
				otMini.id = 'oneTrustMini';
				otMini.tabIndex = 0;	// Make the Banner accessible via keyboard
				
				// Mobile – handle tap on the Cookie icon (Expand the banner)
				otMini.addEventListener('touchend', function (e) {
					// Check if the 'expandedBannerCssClass' is already applied
					if (!otMini.classList.contains(expandedBannerCssClass)) {
						e.preventDefault(); // Prevent default only if the banner is not expanded (without this, the 'Reject' button was activated when the banner expanded)
						otMini.classList.add(expandedBannerCssClass);	// Expand the banner
					}
				});

				//
				// 2.2 Create Cookie icon
				//
				const cookieIcon = document.createElement('div');
				cookieIcon.id = 'oneTrustMini__cookie-icon';
				otMini.appendChild(cookieIcon);
	
				//
				// 2.3 Create Content container
				//
				const contentContainer = document.createElement('div');
				contentContainer.id = 'oneTrustMini__content';
				otMini.appendChild(contentContainer);
	
				//
				// 2.3.1 Create Policy Text container
				//
				const policyText = document.createElement('div');
				policyText.id = 'oneTrustMini__text';
	
				// Create the span element for the text
				const span = document.createElement('span');
				span.textContent = otData.text + ' '; // text "This site uses" + add space character after the text
	
				// Create Cookies policy link
				const link = document.createElement('a');
				link.href = otData.cookiePolicyLink.href;
				link.target = '_blank';
				link.id = 'oneTrustMini__policy-link';
				link.setAttribute('aria-label', otData.cookiePolicyLink.ariaLabel);
				link.textContent = otData.cookiePolicyLink.text; // link text - "cookies"
	
				// Append content to the Text container
				policyText.appendChild(span);
				policyText.appendChild(link);
	
				//
				// 2.3.2 Create Buttons container
				//
				const buttonsDiv = document.createElement('div');
				buttonsDiv.id = 'oneTrustMini__buttons';
	
				// Create Accept button
				const acceptButton = document.createElement('button');
				acceptButton.id = 'oneTrustMini--accept';
				acceptButton.textContent = otData.buttons.accept;
	
				// Handle click on Accept button
				acceptButton.addEventListener('click', function(){
					OneTrust.AllowAll(); // Call OneTrust SDK function
					otMini.classList.add(hiddenBannerCssClass); // Hide banner
				});
	
				// Create Reject button
				const rejectButton = document.createElement('button');
				rejectButton.id = 'oneTrustMini--reject';
				rejectButton.textContent = otData.buttons.reject;
	
				// Handle click on Reject button
				rejectButton.addEventListener('click', function(){
					OneTrust.RejectAll(); // Call OneTrust SDK function
					otMini.classList.add(hiddenBannerCssClass); // Hide banner
				});
	
				// Append buttons to the Buttons container
				buttonsDiv.appendChild(acceptButton);
				buttonsDiv.appendChild(rejectButton);
	
				//
				// 2.3 Append content to the Content container
				//
				contentContainer.appendChild(policyText);
				contentContainer.appendChild(buttonsDiv);
	
				//
				// 2.4 Append the whole Banner to the body
				//
				document.body.appendChild(otMini);
			}
			createOneTrustMiniBanner();
	
	
			//
			//	3. Expand/Collapse OneTrust Mini banner on scroll
			//
			function toggleBannerOnScroll() {
				window.addEventListener('scroll', function () {
					// Get the scroll position
					const scrollPosition = window.scrollY || document.documentElement.scrollTop;
	
					// Expand/Collapse the Banner when scrolled more then 200px from the top
					if (scrollPosition > 200) {
						if (isAcademyPage) {
							// Collapse Banner on Academy pages
							otMini.classList.remove(expandedBannerCssClass);
						} else {
							// Expand Banner on all other pages
							otMini.classList.add(expandedBannerCssClass);
						}
					}
				});
			}
			toggleBannerOnScroll();


			//
			//	4. Academy pages
			//
			function academyPagesBanner() {
				if (isAcademyPage) {
					// Banner is open by default (and collapsed on scroll)
					otMini.classList.add(expandedBannerCssClass);	// expand the Banner


					// Academy pages have Sticky Bar. Move Cookie Consent Banner above the Sticky Bar
					function adjustCookieConsentBannerPositionOnAcademyPages() {
						const bodyClasses = document.body.className.split(' ');
					
						// Extract platform class from <body> (for example "os-android")
						function getPlatformClass(classes) {
							for (var i = 0; i < classes.length; i++) {
								if (classes[i].startsWith('os-')) {
									return classes[i];
								}
							}
							return null;
						}
					
						const platformClass = getPlatformClass(bodyClasses);

						//
						//	Get reference to Sticky bars
						//
						/*
							Academy pages are using 3 different sticky bars:
							1. mobileStickyBar – used on Article detail template (example: https://www.avg.com/en/signal/what-is-malware)
							2. desktopStickyBar - used on Article detail template. Visible from 993px and up (example: https://www.avg.com/en/signal/what-is-malware)
						*/
						const mobileStickyBar = document.querySelector('.sticky-post-widget');
						// Get the Sticky bar currently in use (a bar exists for each platform; retrieve the appropriate one)
						const desktopStickyBar = platformClass ? document.querySelector('.sidebar-sticky-wrapper .os-detect.' + platformClass + ' .sidebar-widget') : null;

						// Adjust position of the Cookie Consent Banner
						function adjustCookieConsentBannerMargin(stickyBar, isVisible) {
							// when the Sticky Bar is visible, get its height and set it as margin to the Cookie Consent Banner.
							// when the Sticky Bar NOT visible, reset margin to 0px
							const height = isVisible ? stickyBar.offsetHeight : 0;
							otMini.style.marginBottom = height + 'px';
						}
					
						function checkMobileStickyBarVisibility() {
							// check if the stickyBar exists and it's not hidden via CSS
							const isVisible = mobileStickyBar && window.getComputedStyle(mobileStickyBar).display !== 'none';
							adjustCookieConsentBannerMargin(mobileStickyBar, isVisible);
						}
					
						function checkDesktopStickyBarVisibility() {
							if (!desktopStickyBar) return;	// check if the stickyBar exists
							const style = window.getComputedStyle(desktopStickyBar);
							// this unique combinations indicates that the stickyBar is sticked to the bottom of the screen and is visible
							const isVisible = style.display === 'flex' && style.position === 'fixed';
							adjustCookieConsentBannerMargin(desktopStickyBar, isVisible);
						}


						// Only one Sticky Bar is visible at a time. Call the appropriate function.
						function checkStickyBarVisibility() {
							const viewportWidth = window.innerWidth;
					
							if (viewportWidth < 993) {
								checkMobileStickyBarVisibility();
							} else {
								checkDesktopStickyBarVisibility();
							}
						}
					
						// Initial visibility check
						//checkStickyBarVisibility();	// Keep this disabled. It's causing buggy behaviour.
					
						// Re-check visibility on scroll and window resize
						window.addEventListener('scroll', checkStickyBarVisibility);
						window.addEventListener('resize', checkStickyBarVisibility);
					}
					adjustCookieConsentBannerPositionOnAcademyPages();
				}
			}
			academyPagesBanner();
		}
	}
	oneTrustMiniBanner();
}
